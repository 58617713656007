import { ColDef } from '@ag-grid-community/core';
import {
  caseInsensitiveComparator,
  dateComparator,
} from "utils/aggrid";
import { formatDateStringToMMMYearDisplay } from 'utils/dates';

export const getColumnDefs = (): Array<ColDef> => ([
  {
    headerName: 'Earned Month',
    field: 'commissionEarnMonth',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => formatDateStringToMMMYearDisplay(value) || '-',
    comparator: dateComparator(),
  },
  {
    headerName: 'Payout Month',
    field: 'commissionPayoutMonth',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => formatDateStringToMMMYearDisplay(value) || '-',
    comparator: dateComparator(),
  },
  {
    headerName: 'Plan Name',
    field: 'planName',
    sortable: true,
    resizable: true,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
  },
  {
    headerName: 'Sales Rep E-mail',
    field: 'salesRepRepEmail',
    sortable: true,
    resizable: true,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
  },
  {
    headerName: 'Closed SQLs',
    field: 'closedSqls',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'rightAligned',
    aggFunc: 'sum',
  },
  {
    headerName: 'Earned SQL Commission',
    field: 'earnedSqlCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'POC Count',
    field: 'pocCount',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'rightAligned',
    aggFunc: 'sum',
  },
  {
    headerName: 'POC Bonus Rate',
    field: 'pocBonusRate',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'POC Commission',
    field: 'pocCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Second Meeting Amount',
    field: 'secondMeetingAmount',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Second Meeting Rate',
    field: 'secondMeetingRate',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => `${(value * 100).toFixed(2)}%`,
    type: 'rightAligned',
    aggFunc: 'sum',
  },
  {
    headerName: 'Second Meeting Compensation',
    field: 'secondMeetingCompensation',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Total Earned Commission',
    field: 'totalEarnedCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Payment Adjustment',
    field: 'paymentAdjustment',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Owed Commission Payment',
    field: 'owedCommissionPayment',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Commission Note',
    field: 'commissionNote',
    sortable: true,
    resizable: true,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
  },
  {
    headerName: 'Net New ARR',
    field: 'netNewArr',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Closed ARR BCR',
    field: 'closedArrBcr',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => `${(value * 100).toFixed(2)}%`,
    type: 'rightAligned',
    aggFunc: 'sum',
  },
  {
    headerName: 'Closed ARR Commission',
    field: 'closedArrCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
]);
